function pantry_github(){
    window.location.href = "https://github.com/rrajj/Pantry-Tracker.git";
}

function winner(){
    window.location.href = "https://code-the-vote.devpost.com/updates/31067-and-the-winner-is";
}

function aws_project(){
    window.location.href = "https://rraj.me";
}

function ai_customer(){
    window.location.href = "https://github.com/rrajj/ai_customer";
}

export {
    pantry_github,
    winner,
    aws_project,
    ai_customer
}